import { Badge, Stack, Text, Box, HStack, Button, useDisclosure } from "@chakra-ui/react";
import { BiReset } from 'react-icons/bi'
import { startCase } from 'lodash';
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import { HiOutlineFilter } from "react-icons/hi";

import { statusMap, colorMap } from "../../../constants";
import OrderFilter from "../OrderFilter";
import Loader from "../../../components/Loader";
import { getOrders } from "../../../store/slices/orderSlices";
import CustomTable from "../../../utils/customTable";

const PayoutOrders = (props) => {
    const dispatch = useDispatch();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const orderData = useSelector((state) => state?.order?.orderDetails);
    const orgId = useSelector((state) => state?.login?.orgDetails?.orgId);
    const isLoading = useSelector((state) => state?.order?.loading);

    const USformatter = new Intl.NumberFormat('en-US');

    useEffect(() => {
        getOrdersData()
    }, []);

    const getOrdersData = useCallback(() => {
        dispatch(getOrders({ filters: { orgId, type: "payout" } }))
    }, [dispatch])

    const columns = [
        {
            Header: "Order ID TransFi",
            accessor: "orderId",
            customerVisibility: true,
            width: '200',
            Cell: ({ value }) => {
                return <Link to={`/order/pay-in-order/${value}`} style={{ color: "#1A4FD6", fontWeight: "bold", fontSize: '14px' }} >{value}</Link>;
            },
        },
        {
            Header: "Order Status",
            accessor: "status",
            customerVisibility: true,
            Cell: ({ value }) => {
                const newStatus = statusMap[value] || value;
                const color = colorMap[value];
                return <Badge colorScheme={color}>{newStatus}</Badge>;
            },
        },
        { Header: "Order ID Customer", accessor: "customer.orgId", customerVisibility: false, width: '200' },
        {
            Header: "Settlement Type", accessor: "type", customerVisibility: false, Cell: ({ value }) => (
                <Text>{value ? startCase(value) : ''}</Text>
            )
        },
        {
            Header: "Customer Name", accessor: "customer.formattedName", customerVisibility: false, width: '200', Cell: ({ value }) => (
                <Text>{value ? startCase(value) : ''}</Text>
            )
        },
        { Header: "Customer User ID/Email", accessor: "customer.email", customerVisibility: true, width: '300' },
        { Header: "Recipient Name", accessor: "recipientDetails[0].firstName", customerVisibility: true, width: '200' },
        { Header: "Recipient Email", accessor: "recipientDetails[0].recipientEmail", customerVisibility: true, width: '200' },
        {
            Header: "Purpose of Transaction", accessor: "purposeCode", customerVisibility: true, Cell: ({ value }) => (
                <Text>{value ? startCase(value) : ''}</Text>
            )
        },
        { Header: "Nature of Sender", accessor: "", customerVisibility: false, width: '200' },
        {
            Header: "Nature of Recipient", accessor: "recipientDetails[0].type", customerVisibility: true, Cell: ({ value }) => (
                <Text>{value ? startCase(value) : ''}</Text>
            )
        },
        { Header: "Jurisdiction of Sender", accessor: "", customerVisibility: false, width: '200' },
        { Header: "Jurisdiction of Recipient", accessor: "userCountry", customerVisibility: true, width: '200' },
        {
            Header: "Initiated Date",
            accessor: "createdAt",
            customerVisibility: true,
            width: '220',
            Cell: ({ value }) => (
                <Text>{moment(value).format("DD-MMM-YYYY @ h:mm A")}</Text>
            ),
        },
        {
            Header: "Updated Date",
            accessor: "updatedAt",
            customerVisibility: true,
            width: '220',
            Cell: ({ value }) => (
                <Text>{moment(value).format("DD-MMM-YYYY @ h:mm A")}</Text>
            ),
        },
        {
            Header: "Crypto Payment Partner", accessor: "crypto.partner", customerVisibility: false, Cell: ({ value }) => (
                <Text>{value ? startCase(value) : ''}</Text>
            )
        },
        {
            Header: "Crypto Partner Order ID", accessor: "crypto.orderId", customerVisibility: false, Cell: ({ value }) => (
                <Text>{value}</Text>
            )
        },
        {
            Header: "Digital Asset", accessor: "depositCurrency", customerVisibility: true, Cell: ({ value }) => (
                <Text>{value}</Text>
            )
        },
        {
            Header: "Crypto Amount Received By TransFi", accessor: "crypto.orderAmount", customerVisibility: true, Cell: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        
        {
            Header: "Crypto Conversion Rate USDC/Crypto", accessor: "crypto.conversionRate", customerVisibility: false, Cell: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            Header: "USDC/USDT Received By TransFi", accessor: "conversionAmount", customerVisibility: false, Cell: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            Header: "Crypto Wallet Address", accessor: "walletAddress", customerVisibility: true, Cell: ({ value }) => (
                <Text>{value}</Text>
            )
        },
        {
            Header: "Fiat Payment Partner", accessor: "paymentGateway", customerVisibility: false, Cell: ({ value }) => (
                <Text>{value ? startCase(value) : 'N/A'}</Text>
            )
        },
        {
            Header: "Fiat Payment Partner Order ID", accessor: "fiat.orderId", customerVisibility: false, width: '200', Cell: ({ value }) => (
                <Text>{value ? startCase(value) : 'N/A'}</Text>
            )
        },
        {
            Header: "Fiat Payment Method", accessor: "paymentName", customerVisibility: true, Cell: ({ value }) => (
                <Text>{value ? startCase(value) : ''}</Text>
            )
        },
        { Header: "Fiat Currency", accessor: "fiatTicker", customerVisibility: true, width: '200' },
        {
            Header: "Conversion Rate(Fiat to USD Rate)", accessor: "conversionRateToUsd", customerVisibility: false, Cell: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            Header: "Gross TXN Amount on Fiat if zero forex markup", accessor: "grossTxnIfZeroForexMarkup", customerVisibility: false, Cell: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            Header: "FX Markup %", accessor: "fxMarkupPercentage", customerVisibility: false, Cell: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            Header: "Conversion rate Used by TransFi", accessor: "conversionRate", customerVisibility: true, Cell: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            Header: "Gross TXN Amount on Fiat", accessor: "grossTxnAmountOnFiat", customerVisibility: true, Cell: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            Header: "TransFi Forex gain USD", accessor: "transfiForexGainUsd", customerVisibility: false, Cell: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            Header: "Processing Fees %", accessor: "processingFees", customerVisibility: true, Cell: ({ value }) => (
                <Text>{value ? USformatter.format(value * 100) + '%' : '0'}</Text>
            )
        },
        {
            Header: "Processing Fees Amount", accessor: "processingFeesAmount", customerVisibility: true, Cell: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            Header: "Amount paid from Sub-Account API", accessor: "amountPaidFromSubAccountApi", customerVisibility: false, Cell: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            Header: "Fiat Delivered To User", accessor: "fiatDeliveredToUser", customerVisibility: true, Cell: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            Header: "Payment Partner Fees", accessor: "paymentPartnerFeesFiat1", customerVisibility: false, Cell: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            Header: "Amount paid from Sub Account Recheck", accessor: "amountPaidFromSubAccountRecheck", customerVisibility: false, Cell: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            Header: "Sub-Account Delivery Date", accessor: "subAmountDeliveryDate", customerVisibility: true, Cell: ({ value }) => (
                <Text>{value}</Text>
            )
        },
        {
            Header: "GTV", accessor: "gtv", customerVisibility: false, Cell: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            Header: "Fiat Delivered To User(In USD)", accessor: "fiatDeliveredToUserInUsd", customerVisibility: false, Cell: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            Header: "Processing Fees Amount(In USD)", accessor: "processingFeesAmountInUsd", customerVisibility: false, Cell: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            Header: "Payment Partner Fees(In USD)", accessor: "paymentPartnerFeesInUsd", customerVisibility: false, Cell: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
    ];

    return (
        <Stack
            direction={"column"}
            justifyContent={"space-between"}
            flexGrow={1}
            p={5}
        >

            {!isLoading ? <Box border="1px">
                <HStack p={5} justifyContent={"flex-end"}>
                    <Button
                        leftIcon={<BiReset style={{ fontSize: "20px" }} />}
                        color={"#525C76"}
                        variant={"outline"}
                        borderColor={"#d2d2d2"}
                        _hover={{ bg: "#ffffff" }}
                        onClick={getOrdersData}
                    >
                        Reset
                    </Button>
                    <Button
                        leftIcon={<HiOutlineFilter style={{ fontSize: "20px" }} />}
                        color={"#525C76"}
                        variant={"outline"}
                        borderColor={"#d2d2d2"}
                        _hover={{ bg: "#ffffff" }}
                        onClick={onOpen}
                    >
                        Filter
                    </Button>
                </HStack>
                <CustomTable columns={columns} data={orderData} />
                <OrderFilter isOpen={isOpen} onClose={onClose} type={"payout"} />
            </Box> : <Loader />}
        </Stack>
    );
};

export default PayoutOrders;